.App {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #1A202B;
}

li {
  cursor: pointer;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar:hover {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #7f797961;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d; 
}
button:focus{ outline: none;}
.MuiTabs-flexContainer button{ color: #fff;}
.rightnav_layout > .MuiPaper-root { top: 60px; overflow: hidden; }
.tabPanel_heading{font-weight: 400;font-size: 16px;display: flex;align-items: center;color: #D9D9D9;margin: 0;padding: 16px 24px;border-bottom: 1px solid #252A3F;}
.object_transform_input  .MuiInputBase-root{background: #272830;border-radius: 4px;font-family: 'Inter';font-style: normal;font-weight: 500;font-size: 14px;line-height: 16px;text-align: center;color: #fff;margin-bottom: 0;width: 90%;display: flex; padding-left: 12px;}
.object_transform_input .MuiOutlinedInput-notchedOutline{ border: none;}
.object_transform_input .MuiInputAdornment-root p{color: #4B9AF7; margin-right: 5px;}
.timeline-slider .timelineSlider { position: absolute; z-index: 11; width: auto; left: 198px; right: 0;}
.timeline-slider .timelineSlider .MuiSlider-rail{ opacity: 1; height: 35px; background: none;}
.timeline-slider .timelineSlider .MuiSlider-thumb{ background: none;top: 0;transform: translate(0);}
.timeline-slider .timelineSlider .MuiSlider-thumb:after{content: "";background: #4490FF;border-radius: 2px;transform: rotate(227deg);position: absolute;width: 18px;height: 18px;left: 1px;top: -5px;}
.timeline-slider .timelineSlider .MuiSlider-thumb:before{width: 1px;height: 230px;border: 1px solid #4490FF;background: #4490FF;top: 0;}
.timeline-slider .timeLine{ margin-left: 9px; height: 35px;}
.timeline-area{ overflow: hidden; width: 100%;}
.timeline-slider .coppy-icon{ top: 6px;}
.button_action_timeline button{ color: #fff;}
.timeStemp{ width: 5%;}

.timeline-slider .MuiSlider-markLabel{ top: 0;    color: #898C94;
  line-height: 16px;
  font-size: 14px;    transform: translate(15px, 15%);}

.timeline-slider .MuiSlider-mark{width: 1px;
  height: 18px;
  background-color: #3E69FA;
  border-radius: 5px;  transform: translate(9px,0);
  top: 0;}

.timeline_row{ display: flex;  justify-content: space-between;}
.timeline_action_part{ min-width: 208px; width: 208px; padding:0 11px 0 19px; border-right: 1px solid #000;}
.timeline_visual_part{ flex-grow: 1;    padding-top: 4px;border-radius: 2px;}

.timeline_visual_part .MuiSlider-rail { background: none;}
.timeline_visual_part .MuiSlider-track {background: #4e4e4e;border-radius: 5px;height: 32px;border: 2px solid #727272;}
.timeline_visual_part .MuiSlider-thumb{ background: none;}
.timeline_visual_part .MuiSlider-thumb:before{ box-shadow: none;}
.timeline_visual_part .MuiSlider-thumb:after{ display: none;}
.timeline_visual_part .MuiSlider-thumb:hover{ box-shadow: none;}
.timeline_visual_part .MuiSlider-valueLabelOpen.MuiSlider-valueLabel{ background: none;transform: translate(0px, 0px);left: 0;top: -4px;}
.timeline_visual_part .MuiSlider-valueLabelOpen::before{ display: none;}
.timeline_visual_part .MuiSlider-mark{
  width: 8px;
  height: 8px;
  background-color: orange;
  transform: rotate(45deg);
  top: 12px;
  margin-left: -3px;
}
.timeline_visual_part .MuiSlider-markLabel{
  color: white;
  line-height: 16px;
  font-size: 14px;
  white-space: pre;
}


.rightsidebar_tabs .MuiButtonBase-root.Mui-selected .MuiSvgIcon-root{ fill: #fff;}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Calistoga&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Borel&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Edu+SA+Beginner&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Handjet&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap'); 

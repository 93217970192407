.titleDiv {
    display: flex;
    padding: 0px !important;
    flex-direction: column;
    gap: 1.5rem;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.title {
    font-family: 'Inter' !important;
    font-weight: 600 !important;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    color: #ffffff !important
}

.round {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: #1A202B;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.icon {
    font-size: 16px !important;
    color: #ffffff;
}

.fieldDiv {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
}

.contentDiv {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
}

.contentDiv1 {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
}

.contentDiv2 {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 98%
}

.optionDiv {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 0.25rem;
    max-width: 30%;
}

.eachOption {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    justify-content: space-between;
}

.eachOptionSelected {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    justify-content: space-between;
    background-color: #394455;
}

.eachOption:hover {
    background-color: #394455;
}

.titleText {
    font-family: 'Inter' !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    line-height: 1.375rem !important;
    color: #AEB6C4 !important
}

.subTItleText {
    font-family: 'Inter' !important;
    font-weight: 500 !important;
    font-size: 0.75rem !important;
    line-height: 1.25rem !important;
    color: #7D899C !important
}

.textDiv {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}


.arrowIcon {
    font-size: 18px;
    color: #7D899C;
}

.lastDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.lastDivGoTo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}

.selectedOption {
    font-family: 'Inter' !important;
    font-weight: 700 !important;
    font-size: 0.75rem !important;
    line-height: 1.25rem !important;
    color: #AEB6C4 !important
}

.selectedOptionRes {
    font-family: 'Inter' !important;
    font-weight: 500 !important;
    font-size: 0.75rem !important;
    line-height: 1.25rem !important;
    color: #7D899C !important
}

.button {
    border: none;
    background-color: #0869FB;
    border-radius: 8px;
    height: 48px;
    width: 153px;
    cursor: pointer;

    font-family: 'Inter' !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    color: #FFFFFF !important;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    padding: 1rem 1.5rem;
}
.asset_div {
    border-radius: 5px;
    border: 4px solid transparent;
    /* width: "100%";
    height: "100%"; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imageContainer {
    width: 100%;
    height: 100px;
    padding: 8px;
    background-color: #394455;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 5px;
    :global &.selected {
      border: 4px solid #74aafd;
    }
  }
.imagesDiv {
    width: calc(100% - 8px);
    aspect-ratio: 1;
    border-radius: 0.75rem;
    cursor: pointer;
    position: relative;
}

.img {
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
}

.iconDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: #1A202B;
    cursor: pointer;
}

.doneIcon {
    color: #fff;
}

.doneDiv {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    position: absolute;
    background-color: #1A202B;
    border: 1px solid #FFF;
    top: 6px;
    right: 6px;
    z-index: 2;
}

.unSelectedDiv {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: #1A202B;
    position: absolute;
    border: 1px solid #FFF;
    top: 6px;
    right: 6px;
    z-index: 2;
}

.verticalItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 16px;
    background-color: #394455;
    border-radius: 8px;
    justify-content: space-between;
}

.smallImg {
    width: 36px;
    height: 36px;
    border-radius: 8px;
}

.verticalSubItem {
    display: flex;
    align-items: center;
    gap: 4px;
}
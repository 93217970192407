.header__right {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.header__profile {
    cursor: pointer;
}

.header__logo_image {
    cursor: pointer;
}

.dd-button {
    cursor: default;
}

.render_subbtn {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #E5E5E5;
    max-width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.render_btn_div {
    background: #111314;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid #3E69FA;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #E5E5E5;
    max-width: 250px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.render_subbtn:hover {
    color: #E5E5E5;
}

.left__ {
    border-left: 1px solid #3E69FA;
}

body {
    padding: 0 !important;
    overflow: auto !important;
}

.header_logo {
    display: flex;
    align-items: center;
}
